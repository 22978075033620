:root {
    --white: #ffffff;
  
    --grey: #f5f5f5;
    --grey2: #c7c7c7;
    --grey3: #9f9f9f;

    --dark-grey: #747474;
  
    --main-green: #008B50;
  
    --fade-green: #ccf2e2;
    --pinkish: #ffdddd;
    --red: #ff0000;

    --black: #000;
}

body,html {
    overflow-x: hidden;
}


/* nav bar */

.foodbank-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px 50px;
    background-color: var(--white);
    box-shadow: 0 2px 10px rgb(247, 246, 246);
    position: sticky;
    top: 0px;
    z-index: 1000;
}

.fn-logo {
    width: 140px;
    height: fit-content;
    max-width: 140px;
    max-height: fit-content;
}

.fn-logo1 {
    width: 200px;
    height: fit-content;
    max-width: 200px;
    max-height: fit-content;
}

.fn-btn {
    display: flex;
    justify-content: center;
    text-decoration: none;
    padding: 10px 25px;
    color: var(--white);
    border-radius: 50px;
    background-color: var(--main-green);
    position: relative;
    transition: 1s;
    overflow: hidden;
}
.fn-btn::after {
    content: '';
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #000;
    transform: translateY(50%);
    transition: 1s;
}

.fn-btn:hover::after {
    transform: translateY(-20%);
}

.fn-btn:hover :where(p) {
    position: relative;
    z-index: 100;
}
/* nav bar end */

.ads{
    width: 60%;
     margin: 8rem;
      background: #141414;
       border-radius: 8px;
        padding: 5rem 0
}

.ads-input{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ads-input1, .ads-input2{
    width: 48%;
}

.ads-btn{
    width: 30%;
    font-weight: 600;
}

@media (max-width: 820px) { 
    .ads{
        width: 90%;
        margin: 5rem;
        padding: 3rem 0
    }

    .ads-input{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }

    .ads-input1, .ads-input2{
        width: 100%;
        margin-bottom: 1rem;
    }
    .ads-btn{
        width: 65%;
    }
    
    
}




/* footer */

.foodbank-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 50px;
    background-color: #141414;
    text-decoration: none;
}

.footer-logo {
    width: 90px;
    height: fit-content;
    max-width: 90px;
    max-height: fit-content;
}

.ff-links {
    display: flex;
    align-items: center;
    gap: 10px;
}
.ff-links a {
    position: relative;
    text-decoration: none;
    color: var(--white);
    font-size: .9rem;
    transition: .5s;
}
.ff-links a::after {
    content: '';
    position: absolute;
    left: 0px;
    bottom: -2px;
    width: 0%;
    height: 2px;
    background-color: white;
    transition: .5s;
}.ff-links a:hover::after {
    width: 100%;
}

/* footer end */




/* landing page */

.lp-header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 30px 10px;
}

.header-subheading {
    color: var(--black);
    font-size: 2.5rem;
    font-weight: 700;
    max-width: 700px;
    margin: 50px auto 0 auto;
    text-align: center;
    transition: .5s;
}

.header-text {
    color: var(--dark-grey);
    max-width: 700px;
    margin: 20px auto 0 auto;
    text-align: center;
}


.lp-header-links {
    display: flex;
    gap: 20px;
    padding: 0 20px;
    margin-top: 30px;
}

.lp-header-links a {
    display: flex;
    justify-content: center;
    text-decoration: none;
    padding: 10px 25px;
    min-width: 150px;
    color: var(--white);
    border-radius: 5px;
    background-color: var(--main-green);
    position: relative;
    transition: 1s;
    overflow: hidden;
}
.lp-header-links a::after {
    content: '';
    position: absolute;
    width: 200px;
    height: 200px;
    background-color: #000;
    transform: translateY(50%);
    transition: 1s;
}

.lp-header-links a:hover::after {
    transform: translateY(-20%);
}

.lp-header-links a:hover :where(p) {
    position: relative;
    z-index: 100;
}


.after-header-image {
    display: block;
    margin: 30px auto 50px auto;
    padding: 0 40px 0 50px;
    width: 100%;
    height: 800px;
    max-width: 100%;
    max-height: fit-content;
    border-radius: 20px;
}

.after-header-image1 {
    display: block;
    margin: 30px auto 50px auto;
    padding: 0 40px 0 50px;
    width: 100%;
    height: 600px;
    max-width: 100%;
    max-height: fit-content;
    border-radius: 20px;
}


.second-subheading-space {
    margin: 30px 0 50px 0;
    padding: 0 20px;
}

.second-subheading {
    color: var(--black);
    font-weight: 600;
    max-width: 600px;
    margin: 50px auto 0 auto;
    text-align: center;
    transition: .5s;
}



.lp-grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 1.8fr;
    gap: 20px;
    height: 1100px;
    padding: 0 50px;
    margin-bottom: 60px;
}

.lp-grid-container section {
    padding: 50px 30px 0 30px;
    border-radius: 10px;
    color: var(--white);
}

.lp-grid-container section p {
    color: var(--grey);
    max-width: 300px;
}

.lp-grid-container section:nth-of-type(1) {
    grid-area: 1/1/2/3;
    background-image: url('../../public/foodbank-images/black-woman-checking-expiration-dates-while-grocery-shopping-supermarket-aisle-with-shopping-trolley-focuses-choosing-healthy-food\ 1.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.lp-grid-container section:nth-of-type(2) {
    grid-area: 2/1/3/2;
    background-image: url('../../public/foodbank-images/smartphone.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.lp-grid-container section:nth-of-type(3) {
    grid-area: 2/2/3/3;
    background-image: url('../../public/foodbank-images/delivering-direct-your-door-shot-courier-making-delivery-smiling-customer\ 1.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.last-content-space1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--white);
    background-color: var(--black);
    overflow: hidden;
    position: relative;
}

.last-content-space {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 100px 20px 80px 20px;
    color: var(--white);
    margin-top: 100px;
    background-color: var(--black);
    overflow: hidden;
    position: relative;
}
.last-content-space :where(h2,.lcs-text, a) {
    position: relative;
    z-index: 100;
}

.last-content-space h1 {
    font-size: 1.5rem;
}

.last-content-space .lcs-text {
    color: var(--dark-grey);
    margin-bottom: 30px;
}


.lcs-circle {
    width: 0px;
    height: 0px;
    border-radius: 50%;
    box-shadow: 0px 0px 180px 180px #02885099;
    background-color: #008B50;
    position: absolute;
    top: -60px;
}


.last-content-space a {
    display: flex;
    justify-content: center;
    text-decoration: none;
    padding: 20px 25px;
    min-width: 150px;
    color: var(--white);
    border: 2px solid transparent;
    border-radius: 5px;
    background-color: var(--main-green);
    position: relative;
    transition: 0.5s;
    overflow: hidden;
}
.last-content-space a::after {
    content: '';
    position: absolute;
    width: 400px;
    height: 200px;
    background-color: #000;
    transform: translateY(50%);
    transition: 0.5s;
}
.last-content-space a:hover {
    border-color: var(--main-green);
    transform: scale(0.99);
}
.last-content-space a:hover::after {
    transform: translateY(-20%);
}

.last-content-space a:hover :where(p) {
    position: relative;
    z-index: 100;
    color: var(--main-green);
}

/* back button */

.back-btn{
    background: rgb(147, 72, 72);
    padding: 10px;

}

@keyframes fadeUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fade-up {
    animation: fadeUp 1s ease-out; 
  }


/* landing page */







@media (max-width: 1000px) { 
    .lp-grid-container {
        height: 800px;
    }
}


@media (max-width: 750px) {

    /* components meadia queries */

    .foodbank-navbar {
        padding: 10px 20px;
    }


    .foodbank-footer {
        padding: 10px 20px;
    }
    .ff-links a {
        font-size: .8rem;
    }

    /* components meadia queries  end*/

    .header-subheading {
        font-size: 1.8rem;
    }

    .after-header-image {
        padding: 0 20px;
        height: 300px;
    }

    .lp-grid-container {
        height: 600px;
        padding: 0 20px;
    }
}

@media (max-width: 600px) {
    .lp-grid-container {
        height: 1000px;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 2fr 2fr;
    }

    .lp-grid-container section:hover {
        transform: scale(1.1);
    }
    
    .lp-grid-container section:nth-of-type(1) {
        grid-area: 1/1/2/2;
    }
    .lp-grid-container section:nth-of-type(2) {
        grid-area: 2/1/3/2;
    }
    .lp-grid-container section:nth-of-type(3) {
        grid-area: 3/1/4/2;
    }

}

@media (max-width: 450px) {
    .fn-btn {
        padding: 5px 10px;
        font-size: .8rem;
    }

    .second-subheading-space .header-text {
        font-size: .8rem;
    }
}

.landingpage-main {
    display: flex;
    justify-content: center;
    gap: 30px;
    width: 100%;
    padding: 60px 0;
    flex-wrap: wrap; 
    margin-bottom: 50px;
}

@media (min-width: 1500px) {
    .landingpage-main {
        padding: 70px;
    }
}
.landingpage-main div {
    display: flex;
    align-items: end;
    height: 250px;
    width: 30%;
    border-radius: 10px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
}
@media (max-width: 600px) {
    .landingpage-main div {
        width: 100%;
    }  
}
/* @media (max-width: 800px) {
    .landingpage-main div {
        width: 30%;
    }  
} */
.landingpage-main div:hover .main-links {
    height: 100%;
}

.landingpage-main div a {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #008b51a7;
    width: 100%;
    color: white;
    height: 50px;
    border-radius: 0 0 10px 10px;
    text-decoration: none;
    font-size: 1.2rem;
    transition: all .9s;
}

.searchcategory{
    padding: 10px;
    border-radius: 8px;
    width: 25vw;
    outline: none;
}

.searchcategory-icon{
    color: var(--main-green);
    font-size: larger;
}

.search-category-wrapper:hover{
    border: 1px solid var(--main-green);
}

@media (max-width: 700px) {
    .search-category-wrapper{
        display: none;
    }
}